@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/** Custom styles go here **/
#blnce-checkout > div {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
